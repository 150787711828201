/* WelcomeUserPage.module.css */
.container {
  height: 100vh;
  width: 100vw;
  overflow-y: scroll !important;
  overflow-x: hidden;
}
.main {
  width: 100%;
  /* padding: 30px 20px 20px 20px; */
  padding-bottom: 20px;
  min-height: 92vh;
}
.header {
  height: 60px;
  position: sticky !important;
  top: 0px !important;
  background-color: white;
  padding: 10px 20px 10px 20px;
  box-shadow: 0px 5px 0px 0px #a89d9d40;
  z-index: 1;
  display: flex;
  justify-content: space-between;
}
/* .header>div{
  color: green
} */
.welcome {
  color: var(--primary-color);
  width: 70%;
  height: 100%;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.welcometext {
  display: flex;
  font-size: 20px;
  font-weight: 600;
}
.usernametext {
  display: flex;
  align-items: center;
  font-size: 21px;
  font-weight: 500;

}
.menuIcon:hover {
  rotate: 40deg;
  animation: 0.4s ease-in-out 1s infinite alternate;
}
.ordersHeading{
  padding-bottom: 3px;
  font-size: 20px;
  font-weight: 500;
  line-height: 24.2px;
  color: black !important;
  position: relative;

}
.ordersHeading span:before {
  content: "";
  border-bottom: 3px solid #fcaf17;
  width: 25px;
  position: absolute;
  float: left;
  bottom: -1px;
  left: 0%;
}
.ordersHeading span:after {
  content: "";
  border-bottom: 3px solid #99a123;
  width: 60px;
  position: absolute;
  float: left;
  bottom: -1px;
  left: 29px;
}
.ordercard {
  max-height: 200px;
  width: 90% !important;
  border: 1px solid #dee2e6;
  margin-left: 20px;
  padding: 18px;
  /* box-shadow: 0px 4px 15.1px 0px #b39d8a40; */
  background-color: #ffffff;
  /* display: flex;
  align-items: center;
  justify-content: center; */
}

.mainitemname {
  color: var(--fifth-color);
  font-weight: 600;
  width: 90%;
  font-size: 20px;
  line-height: 24.2px;
  letter-spacing: 0.02em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;

  /* Add this property to prevent text wrapping */
}

.hrStyle {
  color: #757272;
  margin: 7px 0;
}

.tabledetails {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap:10px;
  bottom: 0;
}

.tableInfo {
  font-size: 15px;
  width: 90% !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 18.15px;
}

.processStatus {
  padding: 3px 10px;
  border-radius: 5px;
  background: #14ff001f;
}

.inProcess {
  background: rgba(255, 20, 0, 0.12);
  color: #ff1400;
}

.totaldetails {
  display: flex;
  justify-content: space-between;
  bottom: 10px;
  font-size: 25px;
  margin-top: 0px;
}

.totalLabel {
  color: black;
  font-size: 20px;
  font-weight: 500;
  line-height: 24.2px;
  letter-spacing: 0.05em;
}

.totalValue {
  color: var(--primary-color);
  font-size: 20px;
  font-weight: 600;
  line-height: 24.2px;
  letter-spacing: 0.05em;
}

.suggestionsHeading {
  margin: 10px 0 2px;
  color: #555555;
  font-weight: 500;
  font-size: 20px;
  line-height: 24.2px;
  margin-left: 20px;
}

.ordersHeading {
  margin: 0 0 10px;
  color: #555555;
}

.currentOrder::-webkit-scrollbar {
  display: none;
}
