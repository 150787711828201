.store {
  padding-bottom: 86px;
  /* background: #e4e4e4; */
  background: white;
  min-height: 60vh;
  scroll-behavior: smooth;
}
.search {
  background-color: white;
  color: black;
  /* min-height:62vh; */
  /* border-top:1px solid #7e7b7b; */

  margin-top: 10px;
  padding-top: 5px;
}
.store::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
.cutSubCategory {
  color: #1c93ce;
  font-size: 17px;
  font-weight: 600;
  width: 350px;

  word-break: break-word;
}
.CategoryMenu {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: black;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  overflow: auto;
  width: 100vw;
  gap: 17px;
  border-bottom: 3px solid var(--primary-color) !important;
  margin-bottom: 10px;
  scrollbar-width: none;
}


.selected {
  color: var(--primary-color); 
}
.catName {
  position: relative; 
  min-width: 126px;
  width: fit-content;
  transition: color 0.3s;
  text-align: center;
}

.catName.selected::after {
  content: '';
  position: absolute;
  bottom: -15px; 
  left: 50%;
  transform: translateX(-50%);
  border-width: 9px; 
  border-style: solid;
  border-color: transparent transparent var(--primary-color) transparent;
}
.scroller::-webkit-scrollbar-track {
  display: none;
}