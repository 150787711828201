@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
* {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-variation-settings: "slnt" 0;
}
button {
  overflow: hidden;
  position: relative;
  display: inline-block;
}

button:before {
  pointer-events: none;
  background: white;
  content: "";
  height: 155px;
  left: -50px;
  opacity: 0.1;
  position: absolute;
  top: -50px;
  transform: rotate(35deg);
  width: 35px;
  /* z-index: 1; */
  animation: effect 3s linear infinite both;
}

@keyframes effect {
  from {
    transform: translateX(-100%) rotate(35deg);
  }
  to {
    transform: translateX(100vw) rotate(35deg);
  }
}

div.slick-track {
  height: 225px !important;
  padding-top: 10px !important;
}
html,
body {
  /* overflow: hidden; Prevent scrolling */
  /* touch-action: none;   -ms-touch-action: none;  */
   user-select: none; /* Prevent text selection */
  /* overflow-y: auto; */
}

body {
  zoom: 1; /* Ensure the zoom level is 100% */
}
