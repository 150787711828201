.restaurentInfo {
  width: 100%;
  background-repeat: no-repeat;
  box-shadow: 0px 1px 20.1px -12px hsla(0, 0%, 0%, 0.588);
  height: 180px;
  background-size: 100% 100%;
  position: relative;
  background-image: url("../../assets/banner1.jpg");
  /* background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url("../../assets/background.png"); */
}
.information {
  margin-top: 0px;
  text-align: center;
  width: 100%;
}
.searchIcon {
  transform: rotate(45deg);
  color: white;
}
.name {
  color: "#c3001a";
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  max-height: 44px;
  overflow: hidden;
}
.loginBtnH {
  color: white;
  text-decoration: none;
  font-weight: 600;
  font-size: 16px;
  border: 2px solid;
  padding: 6px 10px;
  margin-bottom: 4px;
}
.address {
  color: "white";
  font-size: 16px;
  line-height: 19.36px;
  letter-spacing: 0.03em;
  /* word-break: keep-all; */
  max-height: 39px;
  width: 100%;
  white-space: wrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
.restaurentInfoSecond {
  width: 100%;
  background-repeat: no-repeat;
  transition: top 0.4s ease-in;
  background-color: var(--primary-color);
  z-index: 81;
  position: fixed;
  cursor: pointer;
  /* box-shadow: 0px 4px 18.8px -3px #0d0b0b96; */
  padding-bottom: 10px;
}
.controls {
  display: flex;
  width: 100vw;
  justify-content: space-between;
  align-items: center;
  color: white;
  padding-right: 10px;
  padding-left: 20px;
  padding-bottom: 5px;
  overflow: hidden;
  padding-top: 5px;
  z-index: 1010;
  transition: cubic-bezier(0.075, 0.82, 0.165, 1);
}
.backArrow {
  background-color: var(--primary-color);
  border-radius: 5px;
  padding: 6px 10px;
  /* width: 25px; */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
}
.controlUser {
  width: max-content;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.controlsmain {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: 0px 4px 25.1px -12px #00000096;
}
.backmargin {
  margin-right: 31px;
}
.addresssecond {
  width: 78%;
  margin: 4px 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: rgb(173, 173, 173);
  font-size: 14px;
  line-height: 18.15px;
  letter-spacing: 0.03em;
}

.logo {
  max-height: 90px;
  min-width: 90px;
  background-color: white;
  overflow: hidden;
  border-radius: 10px;
  z-index: 10;
  gap: 0px;
  border: 2px solid var(--second-color);
}
.container {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 10px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  border: 2px solid #fff;
  left: 50%;
  bottom: 10px;
  transform: translate(-50%);
  width: 95%;
  border-radius: 10px;
}
.container .name {
  font-size: 25px;
  text-transform: capitalize;
  color: #c3001a;
}
.container .address {
  color: #000000;
  font-size: 13px;
  text-transform: capitalize;
}
.logo img {
  height: 90px;
  width: 90px;
  /* border-radius: 20px; */
}
.sidebaroutside {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 10;
  background-color: rgba(255, 254, 254, 0.6);
}
.sidebar {
  position: absolute;
  top: 0;
  left: 0;
  width: 70vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
  /* transition: left 0.1s linear; */
  z-index: 11;
}
.sidebarInside {
  width: 100%;
  height: 100%;
  z-index: 10;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.sidebarLogin {
  width: 100%;
  height: 100%;
  z-index: 10;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.width {
  width: 90%;
}
.user {
  margin-top: 50px;
  height: 88px;
  width: 88px;
}
.userdetail {
  width: 100%;
  text-align: left;
}
.addimage {
  font-size: 17px;
  line-height: 18.15px;
  margin-bottom: 20px;
  color: #4f4f4f;
  font-weight: 300;
}
.userdetail hr {
  width: 100%;
  height: 1px;
  border: 0;
  background-color: rgba(139, 139, 139, 1);
}
.userinformation {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: black;
}
.userinformation span {
  color: #8b8b8b;
  padding-left: 20px;
  font-size: 18px;
  line-height: 19.36px;
  width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.icons {
  margin-left: 10px;
  font-size: 20px !important;
  width: 16px;
  height: 18px;
  cursor: pointer;
}
.logout {
  width: 100%;
  height: 10vh;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}
.logout hr {
  color: rgba(139, 139, 139, 1);
  width: 90%;
}
.logout span {
  color: rgba(139, 139, 139, 1);
}
.back {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 100;
  color: rgba(139, 139, 139, 1);
}
.controlicons {
  margin-left: 5px;
  font-size: 24px;
}
.loginbtn {
  width: 197px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 49.75px;
  border-radius: 20px;
  font-size: 18px;
  color: white;
  font-weight: 600;
  background-color: #f64a0e;
  border: none;
  margin: auto;
  box-shadow: 0px 4px 5.4px 2px #00000040;
}
.editProfile {
  width: 75%;
  background: #fa4a0c;
  color: white;
  outline: none;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  border-radius: 15px;
  font-size: 14px;
  box-shadow: 0px 4px 5.4px 2px #00000040;
  font-size: 18px;
  line-height: 24.2;
  font-weight: 500;
}
.topresname {
  color: white;
  font-size: 22px;
  font-weight: 600;
  line-height: 29.05px;
}

.rating {
  font-family: monospace !important;
  font-weight: 300;
  font-size: 16px;
  display: inline-flex;
  align-items: center;
}
span > svg > circle {
  fill: #1a8e3e;
}
span > svg > polygon {
  fill: #ffffff;
}
.ratingvalue {
  font-size: 15px;
  margin-left: 3px;
  font-weight: 500;
}

.shimmer {
  background: linear-gradient(-45deg, #eee 40%, #fafafa 50%, #eee 60%);
  background-size: 300%;
  height: 80px;
  /* margin-top: 14px; */
  overflow: hidden;
  width: 95%;
  background-position-x: 100%;
  animation: shimmer 1s infinite linear;
  border-radius: 14px;
}
@keyframes shimmer {
  to {
    background-position-x: 0%;
  }
}
/*  */
.searchContainer {
  position: relative;
  display: inline-block;
  margin: 4px 2px;
  height: 50px;
  width: 50px;
  vertical-align: bottom;
}

.button {
  display: inline-block;
  /* margin: 4px 2px; */
  /* background-color: #9f9d9d; */
  font-size: 16px;
  padding-left: 32px;
  padding-right: 32px;
  /* height: 50px; */
  /* line-height: 50px; */
  text-align: center;
  color: white;
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  right: 0;
  /* border-top-right-radius: 25px;
  border-bottom-right-radius: 25px; */
}

.button:hover {
  transition-duration: 0.4s;
  /* background-color: white; */
  color: black;
}

.mglass {
  display: inline-block;
  pointer-events: none;
  transform: rotate(-45deg);
}

.searchbutton {
  position: absolute;
  font-size: 30px;
  width: 100%;
  margin: 0;
  padding: 0;
  /* margin-top: inherit; */
}
.crossIcon {
  font-size: 26px;
  font-weight: 600;
  position: absolute;
  top: 7px;
  right: 15px;
  color: rgb(255, 255, 255);
  background-color: #c3001a;
  border-radius: 50%;
  z-index: 100;
  cursor: pointer;
}

.search {
  position: absolute;
  left: 49px;
  background-color: white;
  outline: none;
  border: none;
  padding: 0;
  width: 0;
  height: 100%;
  z-index: 10;
  transition-duration: 0.4s;
}
.search1 {
  height: 30px !important;
  margin: 10px 0px !important;
}
.result {
  position: fixed;
  top: 65px;
  max-width: 100%;
  background-color: white;
  /* height: 85%; */
  height: "100%";
  max-height: 639px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 76px;
  left: 0px;
  z-index: 1000;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  margin: 0px 5px;
  width: 98%;
}
.result1 {
  position: fixed;
  top: 43px;
  max-width: 100%;
  background-color: white;
  /* height: 85%; */
      height: "100%";
      max-height: 639px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 76px;
  left: 0px;
  z-index: 1000;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  margin: 0px 5px;
  width: 98%;
}
.noResult {
  font-size: 18px;
  text-align: center;
  color: rgb(121, 116, 116);
  margin-top: 20px;
  padding: 20px;
}
.focused {
  width: 68vw;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  padding: 0 16px 0 0;
}
.focused1 {
  width: 50vw !important;
  border-radius: 25px;
  padding: 0px 10px 0 10px !important;
  font-size: 15px;
}
.expandright {
  left: auto;
  right: 49px;
}

.expandright.focused {
  padding: 0 0 0 16px;
}
.overlayHelper {
  position: fixed;
  top: 0;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.7);
  height: 100vh;
  z-index: 100;
}
.helperContainer {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: rgb(255, 253, 253);
  /* height: 85%; */
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 76px;
  z-index: 1000;
}

.noScroll {
  overflow: hidden;
}
.menu {
  background: #c3001a;
  line-height: 22px;
  font-size: 20px;
  color: white;
  font-weight: 500;
  box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.3);
  z-index: 99999;
  top: 0;
  position: fixed;
  width: 100%;
}
.menuhead {
  /* padding: 10px 255px 10px 10px; */
  padding-left: 10px;
}
.paddingTenpx{
  padding: 10px 10px 10px 2px;
}
.menudownIcon {
  margin-left: 12px;
}
.menu::selection {
  color: #fff;
  background-color: #c3001a;
}
.stickyWrapper {
  padding: 47px 10px 19px 10px;
  background: rgb(255, 255, 255);
  width: 100%;
  float: left;
  position: fixed;
  box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.3);
  min-height: fit-content;
  top: 0;
  z-index: 9999;
}
.CatName {
  width: 100%;
  float: left;
  color: #444444;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
}
.FoodItem {
  font-size: 14px;
  line-height: 1.42857143;
}
.FoodList {
  margin-bottom: 15px;
  margin-top: 0px;
}
.subcatname {
  width: 100%;
  float: left;
  color: #868686;
}
.subcatname:active {
  color: var(--primary-color);
}
.subcatname:target {
  color: var(--primary-color);
}
